.body {
  background-color: black;
}

.LandingPage {
  width: 100%;
  min-height: 100vh;
  display: flex;
  /* grid-template-columns: 12% 50px auto 50px 12%; */
  /* grid-template-rows: 150px auto 150px; */
  text-align: left;
  color: #5F67EC;
}

.text-gradient {
  background: #5F68FF;
background: -webkit-linear-gradient(to bottom right, #5F68FF 19%, #FF7663 100%);
background: -moz-linear-gradient(to bottom right, #5F68FF 19%, #FF7663 100%);
background: linear-gradient(to bottom right, #5F68FF 19%, #FF7663 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.width-control {
  max-width: 1152px;
  margin: auto;
}

.w-full {
  width: 100%;
}

.Header-Bar-Container {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
}

.LandingPageComponent {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;

  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.Footer-Bar-Container {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 4;
}

@media (max-width: 768px) {
  .LandingPage {
    grid-template-columns: 16px 50px auto 50px 16px;
  }}

  .bg-cover-image {
    background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
  background-size: 100% 100%;
  }