.Header-Bar-Container {
  width: 100%;
  height: 60px;
  display: grid;
  grid-template-columns: 50% 50%;
}

.Header-Logo-Container {
  grid-column-start: 1;
  grid-column-end: 2;
}

.Header-Action-Container {
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.align-item-center {
  align-items: center;
}

.hidden-md {
  display: none;
}
@media (max-width: 640px) {
  .hidden-sm {
display: none;
  }
  .hidden-md {
    display: block;
  }
}
