.AdsQuoteContainer {
  margin-bottom: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.QuoteComponent {
  width: 100%;
  height: 340px;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 13px;

  display: flex;
  flex-direction: column;
}

.QouteSymbolContainer {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.02em;

  flex-grow: 2;
}

.QuoteMessageContainer {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.02em;

  flex-grow: 2;
}

.QuoteCreditContainer {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  opacity: 0.6;

  flex-grow: 4;
  align-content: flex-end;
}

@media (max-width: 768px) {
.AdsQuoteContainer {
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
}