@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&display=swap');

body div {
    font-family: 'Inter', sans-serif;
}

.page-width {
    max-width: 1152px; 
    margin: 0 auto;
}

.font-color-primary {
    color: #5F67EC;
}

.text-left {
    text-align: left;
}

.font-reg {
    font-size: 60px;
    font-weight: 400;
}

.text-xs{	font-size: 0.75rem;
line-height: 1rem;}

.text-sm{	font-size: 0.875rem;
line-height: 1.25rem;}
.text-base	{font-size: 1rem;
line-height: 1.5rem;}
.text-lg	{font-size: 1.125rem;
line-height: 1.75rem;}
.text-xl{font-size: 1.25rem;
line-height: 1.75rem;}
.text-2xl{font-size: 1.5rem;
line-height: 2rem;}


.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.absolute {
    position: absolute;
}
.relative {
    position: relative;
}

.justify-between {
    justify-content: space-between;
}

.justify-start {
    justify-content: start;
}

.justify-center {
    justify-content: center;
}

.align-item-center {
    align-items: center;
}

.left-0 {
    left: 0;
}

.top-0 {
    top: 0;
}

.login-button {
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;

height: 58px;
background: #5F67EC;
border-radius: 4px;
border: none;
color: white;
font-size: 16px;
width: 200px;
cursor: pointer;
font-weight: 600;
}


.btn-primary-light {
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
height: 58px;
background: #5F67EC29;
border-radius: 4px;
border: none;
color: #5F67EC;
font-size: 16px;
width: 200px;
cursor: pointer;
font-weight: 600;
}



.btn-secondary {
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
height: 58px;
background: #EAEAEA;
border-radius: 4px;
border: none;
color: #000000;
font-size: 16px;
width: 200px;
cursor: pointer;
font-weight: 600;
}



.bold {
    font-weight: 600;
}

.card-border {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 24px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
   
}

.input-box {
    background: #EDEDED;
    border: none;
    color: #000000;
    font-size: 16px;
    cursor: pointer;
   min-height: 55px;
   padding: 0 16px;
   font-family: 'Inter', sans-serif;
}

.textarea-box {
    background: #EDEDED;
    border: none;
    color: #000000;
    min-height: 250px;
    font-size: 16px;
    cursor: pointer;
   min-height: 55px;
   padding: 16px;
   font-family: 'Inter', sans-serif;
}

.w-full {
    width: 100%;
}

.w-30 {
width: 30%;
}

.w-70 {
    width: 70%;
    }

.m-auto {
    margin: 0 auto;
}

.underline {
    text-decoration: underline;
}

.hz-container {
    position: relative;
    width: 100%;
    background: #f7f7f7;
    display: flex;
    overflow: auto;
   
  }
 
  .flex-scroll {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
  
  .attribute-card {
    width: 250px;
    height: 258px;
    margin: 4px;
    padding: 8px;
  }
  
.img-container {
    margin-top: 8px;
    height:85%;
    width: 85%;
    object-fit: cover;
    max-width: 250px;
    
}

.img-container-l {
    margin-top: 8px;
    height:85%;
    width: 85%;
    object-fit: cover;
}

  .attribute-card:nth-child(3n-2) { background-color: #ffffff; border: 1px solid rgba(0, 0, 0, 0.2);}
.attribute-card:nth-child(3n-1) { background-color: #ffffff; border: 1px solid rgba(0, 0, 0, 0.2);}
.attribute-card:nth-child(3n) { background-color: #ffffff; border: 1px solid rgba(0, 0, 0, 0.2);}

.tab-on-focus {
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 4px 16px;
background: #5F67EC29;
border-radius: 4px;
border: none;
color: #5F67EC;
font-size: 16px;
cursor: pointer;
font-weight: 600;
}


.tab-active {
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 4px 16px;
background: #f7f7f7;
border-radius: 4px;
border: none;
color: #000000;
font-size: 16px;
cursor: pointer;
font-weight: 600;
}

.grid-3 {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.nft-card {
    width: auto;
    aspect-ratio: 1 / 1 ;
    margin: 4px;
    padding: 8px;
  }

  .avatar {
      object-fit: cover;
      border-radius: 100px;
      width: 150px;
      height: 150px;
      background-color: #EAEAEA;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      -ms-border-radius: 100px;
      -o-border-radius: 100px;
}

table.unstyledTable {
    width: 100%;
  }

  table.unstyledTable thead {
    background: #ffffff;
    font-weight: bold;
  }
  table.unstyledTable thead th {
    font-weight: bold;
    font-size: 20px;
  }
 
  .opacity-8 {
      opacity: 0.8;
  }

  .opacity-6 {
    opacity: 0.6;
}


.opacity-4 {
    opacity: 0.4;
}


.opacity-2 {
    opacity: 0.2;
}