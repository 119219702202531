.navbar {
  position: absolute;
  left: 0px;
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  background-color: #DFE1FB;
  color: #5F67EC;
  text-align: center;
}

.status-bar {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  background-color: #5F67EC29;
  color: #5F67EC;
  text-align: center;
  text-align: left;
  border: 1px solid #C9CCFF;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}