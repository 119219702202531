.card-container {
  display: flex;
  flex-direction: column;
  
}

.address-text {
  width: 180px;
  font-size: 13px;
  color: rgb(163, 163, 163);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 
}

.image-box {
  border-radius: 0;
  width: 100%;
}