.SellPageConentContainer {
  padding-top: 32px;
  display: flex;
  flex-direction: column;
}

.profile {
  flex: 0 0 250px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.profile-pic-element {
  display: inline-block;
  vertical-align: top;
}

.project-overview {
  margin-top: 30px;
  margin-bottom: 30px;
}

.project-title{
  color: black;
  font-weight: 900;
  font-size: 20px;
}

.project-description{
  color: black;
  font-size: 16px;
}

.availability {
  color: black;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 30px;
  opacity: .6;
}

.nft {
  padding-left: 100px;
  flex: 1 0;
}

.nft-title {
  color: black; 
  font-weight: 900;
  font-size: 13px;
  margin-top: 20px;
}

.nft-cards {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;
}

.nft-properties {
  display: flex;
  padding-bottom: 30px;
}

.properties-button {
  margin-right: 20px;
}

.social-container {
  display: flex;
  margin-bottom: 16px;
}

.social-button {
  margin-right: 10px;
}

.social-svg{
  background-color: #000000 !important;
}

.social-svg-mask {
  background-color: #DDDDDD  !important;
  fill: #DDDDDD  !important;
}

@media (max-width: 768px) {
  .SellPageConentContainer {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .nft {
    padding-left: 0;
    flex: 1 0;
  }
  .card-container {
    display: flex;
    flex-direction: column;
}
.nft-cards {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
}
}

@media (max-width: 992px) {
  .nft-cards {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
  }
}

.social-container > .social-icon {
width: 32px !important;
height: 32px !important;
}

