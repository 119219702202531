.Footer-Information-Container {
  font-size: 14px;
  line-height: 46px;
  letter-spacing: 0.02em;
  margin-left: 16px;
}

.align-end {
  display: flex;
    align-items: end;
}