.nft-layer-properties {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  margin-bottom: 24px;
}

.main-header {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  width: 100%;
}

.layer-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.layer {
  margin-bottom: 32px;
  border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 24px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.card {
  margin-right: 0;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: white;
}

.card-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.delete-button {
  margin-left: 50px;
}

.layer-card-container {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 20px;
  background-color: #f9f9f9;
}

.nft-preview {
  display: flex;
  flex-direction: row;
}

.sell-image-box {
 
  border-radius: 0;
}

.w-full {
  width: 100%;
}

.delete-icon {
  background-color: transparent;
  color: black;
}

.dropdown-btn {
  background-color: transparent;
  color: #ffffff;
}

.dropdown-btn:focus, .dropdown-btn:hover {
  background-color: #5f67ec29;
  color: #5f67ec;
}