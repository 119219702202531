@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rowdies:wght@300;400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  background: #ffffff;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body div {
    font-family: 'Inter', sans-serif;
}

.page-width {
    max-width: 1152px; 
    margin: 0 auto;
}

.font-color-primary {
    color: #5F67EC;
}

.text-left {
    text-align: left;
}

.font-reg {
    font-size: 60px;
    font-weight: 400;
}

.text-xs{	font-size: 0.75rem;
line-height: 1rem;}

.text-sm{	font-size: 0.875rem;
line-height: 1.25rem;}
.text-base	{font-size: 1rem;
line-height: 1.5rem;}
.text-lg	{font-size: 1.125rem;
line-height: 1.75rem;}
.text-xl{font-size: 1.25rem;
line-height: 1.75rem;}
.text-2xl{font-size: 1.5rem;
line-height: 2rem;}


.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.absolute {
    position: absolute;
}
.relative {
    position: relative;
}

.justify-between {
    justify-content: space-between;
}

.justify-start {
    justify-content: start;
}

.justify-center {
    justify-content: center;
}

.align-item-center {
    align-items: center;
}

.left-0 {
    left: 0;
}

.top-0 {
    top: 0;
}

.login-button {
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;

height: 58px;
background: #5F67EC;
border-radius: 4px;
border: none;
color: white;
font-size: 16px;
width: 200px;
cursor: pointer;
font-weight: 600;
}


.btn-primary-light {
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
height: 58px;
background: #5F67EC29;
border-radius: 4px;
border: none;
color: #5F67EC;
font-size: 16px;
width: 200px;
cursor: pointer;
font-weight: 600;
}



.btn-secondary {
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
height: 58px;
background: #EAEAEA;
border-radius: 4px;
border: none;
color: #000000;
font-size: 16px;
width: 200px;
cursor: pointer;
font-weight: 600;
}



.bold {
    font-weight: 600;
}

.card-border {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 24px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
   
}

.input-box {
    background: #EDEDED;
    border: none;
    color: #000000;
    font-size: 16px;
    cursor: pointer;
   min-height: 55px;
   padding: 0 16px;
   font-family: 'Inter', sans-serif;
}

.textarea-box {
    background: #EDEDED;
    border: none;
    color: #000000;
    min-height: 250px;
    font-size: 16px;
    cursor: pointer;
   min-height: 55px;
   padding: 16px;
   font-family: 'Inter', sans-serif;
}

.w-full {
    width: 100%;
}

.w-30 {
width: 30%;
}

.w-70 {
    width: 70%;
    }

.m-auto {
    margin: 0 auto;
}

.underline {
    text-decoration: underline;
}

.hz-container {
    position: relative;
    width: 100%;
    background: #f7f7f7;
    display: flex;
    overflow: auto;
   
  }
 
  .flex-scroll {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
  
  .attribute-card {
    width: 250px;
    height: 258px;
    margin: 4px;
    padding: 8px;
  }
  
.img-container {
    margin-top: 8px;
    height:85%;
    width: 85%;
    object-fit: cover;
    max-width: 250px;
    
}

.img-container-l {
    margin-top: 8px;
    height:85%;
    width: 85%;
    object-fit: cover;
}

  .attribute-card:nth-child(3n-2) { background-color: #ffffff; border: 1px solid rgba(0, 0, 0, 0.2);}
.attribute-card:nth-child(3n-1) { background-color: #ffffff; border: 1px solid rgba(0, 0, 0, 0.2);}
.attribute-card:nth-child(3n) { background-color: #ffffff; border: 1px solid rgba(0, 0, 0, 0.2);}

.tab-on-focus {
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 4px 16px;
background: #5F67EC29;
border-radius: 4px;
border: none;
color: #5F67EC;
font-size: 16px;
cursor: pointer;
font-weight: 600;
}


.tab-active {
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 4px 16px;
background: #f7f7f7;
border-radius: 4px;
border: none;
color: #000000;
font-size: 16px;
cursor: pointer;
font-weight: 600;
}

.grid-3 {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.nft-card {
    width: auto;
    aspect-ratio: 1 / 1 ;
    margin: 4px;
    padding: 8px;
  }

  .avatar {
      object-fit: cover;
      border-radius: 100px;
      width: 150px;
      height: 150px;
      background-color: #EAEAEA;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      -ms-border-radius: 100px;
      -o-border-radius: 100px;
}

table.unstyledTable {
    width: 100%;
  }

  table.unstyledTable thead {
    background: #ffffff;
    font-weight: bold;
  }
  table.unstyledTable thead th {
    font-weight: bold;
    font-size: 20px;
  }
 
  .opacity-8 {
      opacity: 0.8;
  }

  .opacity-6 {
    opacity: 0.6;
}


.opacity-4 {
    opacity: 0.4;
}


.opacity-2 {
    opacity: 0.2;
}
.Header-Bar-Container {
  width: 100%;
  height: 60px;
  display: grid;
  grid-template-columns: 50% 50%;
}

.Header-Logo-Container {
  grid-column-start: 1;
  grid-column-end: 2;
}

.Header-Action-Container {
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.align-item-center {
  align-items: center;
}

.hidden-md {
  display: none;
}
@media (max-width: 640px) {
  .hidden-sm {
display: none;
  }
  .hidden-md {
    display: block;
  }
}

.PromoContainer {
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
  
}

.PromoHeaderContainer {
  font-size: 100px;
  line-height: 110%;
  letter-spacing: 0.02em;
  font-weight: 100;
  margin-top: -32px;
  padding: 16px;
  font-family: 'Rowdies';
}

.PromoDetailContainer {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.02em;
  padding: 16px;
}

@media (max-width: 768px) {
  
.PromoHeaderContainer {
  font-size: 42px;
  line-height: 110%;
  letter-spacing: 0.02em;
}
}

.AdsContainer {
  /* padding-top: 30px; */
  /* padding-bottom: 30px; */
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
 
}

.AdsPictureContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AdsTopicContainer {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.02em;
}

.AdsPictureItemContainer {
  
  height: 350px;
  background: #FFFFFF;
  padding: 16px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border: 1px solid #5F67EC37;
  border-image-slice: 1;
}

.AdsPictureItem {
  background: #f7f7f7;
  width: 100%;
  height: 313px;
}

/* hz-container */

.hz-container {
  position: relative;
  width: 100%;
  background: #ffffff29;
  display: flex;
  overflow: auto;
  max-width: 100vw;
  border: 1px solid white;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.flex-scroll {
  padding: 16px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

}

.nft-card {
  width: auto;
  margin: 4px;
  padding: 8px;
}

.attribute-card {
  margin: 4px;
  padding: 8px;
}
.img-container-l {
  margin-top: 0px;
  height:100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 414px) {
  
}

.AdsQuoteContainer {
  margin-bottom: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}

.QuoteComponent {
  width: 100%;
  height: 340px;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 13px;

  display: flex;
  flex-direction: column;
}

.QouteSymbolContainer {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.02em;

  flex-grow: 2;
}

.QuoteMessageContainer {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.02em;

  flex-grow: 2;
}

.QuoteCreditContainer {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  opacity: 0.6;

  flex-grow: 4;
  align-content: flex-end;
}

@media (max-width: 768px) {
.AdsQuoteContainer {
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}
}
.RoadmapContainer {
  height: auto;
  padding: 32px 16px;
}

#RoadmapTitle {
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.02em;
}

#RoadmapContent {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.02em;
}

.blur-light {
  position: absolute;
    right: 60px;
    bottom: 22px;
    background: #ff1e00;
    opacity: 1;
    -webkit-filter: blur(160px);
            filter: blur(160px);
    -webkit-transform: rotate(155.04deg);
            transform: rotate(155.04deg);
    height: 300px;
    width: 300px;
}
.Footer-Information-Container {
  font-size: 14px;
  line-height: 46px;
  letter-spacing: 0.02em;
  margin-left: 16px;
}

.align-end {
  display: flex;
    align-items: end;
}
.body {
  background-color: black;
}

.LandingPage {
  width: 100%;
  min-height: 100vh;
  display: flex;
  /* grid-template-columns: 12% 50px auto 50px 12%; */
  /* grid-template-rows: 150px auto 150px; */
  text-align: left;
  color: #5F67EC;
}

.text-gradient {
  background: #5F68FF;
background: linear-gradient(to bottom right, #5F68FF 19%, #FF7663 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.width-control {
  max-width: 1152px;
  margin: auto;
}

.w-full {
  width: 100%;
}

.Header-Bar-Container {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
}

.LandingPageComponent {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;

  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.Footer-Bar-Container {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 4;
}

@media (max-width: 768px) {
  .LandingPage {
    grid-template-columns: 16px 50px auto 50px 16px;
  }}

  .bg-cover-image {
    background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
  background-size: 100% 100%;
  }
.DashboardHeaderContainer {
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 28px;
  line-height: 34px;

  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.InputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
}

#InputTopic {
  color: #000000;
  font-size: 16px;
  font-weight: bold;
}

.text-input {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}
.ProjectCardContainer {
  width: 100%;
  height: 96px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ProjectNameAndStatusContainer {
  display: flex;
  flex-direction: row;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.ProjectDescriptionContainer {
  padding-left: 10px;
}

.ProjectActionContainer {
  padding-right: 10px;
}
.DashboardContentTabContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  border-bottom: 1px solid #000000;
  padding-bottom: 15px;
}

.DashboardContentListContainer {
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.ProjectDashboardPage {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 12% 50px auto 50px 12%;
  grid-template-rows: 150px auto 150px;

  text-align: left;
  color: #5F67EC;
}

.Header-Bar-Container {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
}

.ProjectDashboardPageComponent {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
}

.Footer-Bar-Container {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 4;
}

@media (max-width: 768px) {
.ProjectDashboardPage {
  grid-template-columns: 16px 50px auto 50px 16px;
}}
.navbar {
  position: absolute;
  left: 0px;
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  background-color: #DFE1FB;
  color: #5F67EC;
  text-align: center;
}

.status-bar {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  background-color: #5F67EC29;
  color: #5F67EC;
  text-align: center;
  text-align: left;
  border: 1px solid #C9CCFF;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.SellPageConentContainer {
  padding-top: 32px;
  display: flex;
  flex-direction: column;
}

.profile {
  flex: 0 0 250px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.profile-pic-element {
  display: inline-block;
  vertical-align: top;
}

.project-overview {
  margin-top: 30px;
  margin-bottom: 30px;
}

.project-title{
  color: black;
  font-weight: 900;
  font-size: 20px;
}

.project-description{
  color: black;
  font-size: 16px;
}

.availability {
  color: black;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 30px;
  opacity: .6;
}

.nft {
  padding-left: 100px;
  flex: 1 0;
}

.nft-title {
  color: black; 
  font-weight: 900;
  font-size: 13px;
  margin-top: 20px;
}

.nft-cards {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}

.nft-properties {
  display: flex;
  padding-bottom: 30px;
}

.properties-button {
  margin-right: 20px;
}

.social-container {
  display: flex;
  margin-bottom: 16px;
}

.social-button {
  margin-right: 10px;
}

.social-svg{
  background-color: #000000 !important;
}

.social-svg-mask {
  background-color: #DDDDDD  !important;
  fill: #DDDDDD  !important;
}

@media (max-width: 768px) {
  .SellPageConentContainer {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .nft {
    padding-left: 0;
    flex: 1 0;
  }
  .card-container {
    display: flex;
    flex-direction: column;
}
.nft-cards {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}
}

@media (max-width: 992px) {
  .nft-cards {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 1rem;
    gap: 1rem;
  }
}

.social-container > .social-icon {
width: 32px !important;
height: 32px !important;
}


.card-container {
  display: flex;
  flex-direction: column;
  
}

.address-text {
  width: 180px;
  font-size: 13px;
  color: rgb(163, 163, 163);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 
}

.image-box {
  border-radius: 0;
  width: 100%;
}
.DeployPage {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 12% 50px auto 50px 12%;
  grid-template-rows: 75px auto 150px;

  text-align: left;
  color: #5F67EC;
}

.Header-Bar-Container {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
}

.SellPageComponent {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
}

.Footer-Bar-Container {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 4;
}
@media (max-width: 768px) {
  .DeployPage {
    grid-template-columns: 16px 50px auto 50px 16px;
  }}
.SellPage {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 12% 50px auto 50px 12%;
  grid-template-rows: 75px auto 150px;

  text-align: left;
  color: #5F67EC;
}

.Header-Bar-Container {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
}

.SellPageComponent {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
  max-width: 952px;
  margin: 0 auto;
  width: 100%;
}

.Footer-Bar-Container {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 4;
}
@media (max-width: 768px) {
  .SellPage {
    grid-template-columns: 16px 50px auto 50px 16px;
  }}
.UploadPage {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 12% 50px auto 50px 12%;
  grid-template-rows: 150px auto 150px;

  text-align: left;
}

.UploadPageComponent {
  max-width: 752px;
  margin: auto;
  width: 100%;
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
}

.title {
  color: black;
  font-weight: 900;
  font-size: 40px;
}

.description {
  font-size: 20px;
  color: gray;
  margin-bottom: 30px;
}

.form {
  margin-bottom: 50px;
}

.overlay-container {
  position: relative;
  margin-bottom: 200px;
}

.image-overlay {
  left: 0;
  top: 0;
  position: absolute;
  width: 150px;
  height: 150px;
}

.w-full {
  width: 100%;
}

.primary-button {
    border-radius: 4px;
    background-color: #5F67EC;
    color: #ffffff;
    background: rgb(95,103,236);
    background: linear-gradient(90deg, rgba(95,103,236,1) 0%, rgba(27,40,255,1) 100%);
    height: 36px;
}

.primary-light-button {
  border-radius: 4px;
  background-color: #5F67EC29;
  color: rgb(95,103,236);
 height: 36px;
}

.primary-light-button:hover {
  border-radius: 4px;
  background-color: #5F67EC37;
  color: rgb(95,103,236);
 height: 36px;
}

.text-link {
  background-color: transparent;
    text-decoration: underline;
    color: black;
    -webkit-text-decoration-color: rgba(0,0,0,0.25);
    text-decoration-color: rgba(0,0,0,0.25);
    -moz-text-decoration-color: rgba(0,0,0,0.25);
    box-shadow: none  !important;
}

.text-link:hover, .text-link:active {
  background-color: transparent;
    -webkit-text-decoration-color: rgba(0,0,0,1);
    text-decoration-color: rgba(0,0,0,1);
    -moz-text-decoration-color: rgba(0,0,0,1);
    --tw-shadow: 0;
    box-shadow: none  !important;
}


.hz-container {
  position: relative;
  width: 100%;
  background: #ffffff29;
  display: flex;
  overflow: auto;
  max-width: 100vw;
  border: 1px solid white;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.flex-scroll {
  padding: 16px;
  display: flex;
  width: 120%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
grid-gap: 16px;
gap: 16px;
}

.nft-card {
  width: auto;
  margin: 4px;
  padding: 8px;
  height:auto;
  object-fit: contain;
}

.attribute-card {
  margin: 4px;
  padding: 8px;
}
.img-container-l {
  margin-top: 0px;
  height:190px;
  width: 190px;
  object-fit: contain;
}

tr {
  width: 100%;
}
tr:nth-child(even) {background: #f9f9f9}
tr:nth-child(odd) {background: #ffffff}
td {
  width: 25%;
}

.dropdown-btn {
  background-color: transparent;
  color: #5f67ec !important;
  box-shadow: none;
}

.dropdown-btn:focus, .dropdown-btn:hover {
  background-color: #5f67ec29;
  color: #5f67ec;
  box-shadow: none;
}
.nft-layer-properties {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  margin-bottom: 24px;
}

.main-header {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  width: 100%;
}

.layer-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.layer {
  margin-bottom: 32px;
  border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 24px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.card {
  margin-right: 0;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: white;
}

.card-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.delete-button {
  margin-left: 50px;
}

.layer-card-container {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 20px;
  background-color: #f9f9f9;
}

.nft-preview {
  display: flex;
  flex-direction: row;
}

.sell-image-box {
 
  border-radius: 0;
}

.w-full {
  width: 100%;
}

.delete-icon {
  background-color: transparent;
  color: black;
}

.dropdown-btn {
  background-color: transparent;
  color: #ffffff;
}

.dropdown-btn:focus, .dropdown-btn:hover {
  background-color: #5f67ec29;
  color: #5f67ec;
}
