.DashboardHeaderContainer {
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 28px;
  line-height: 34px;

  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.InputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

#InputTopic {
  color: #000000;
  font-size: 16px;
  font-weight: bold;
}

.text-input {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}