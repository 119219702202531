.UploadPage {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 12% 50px auto 50px 12%;
  grid-template-rows: 150px auto 150px;

  text-align: left;
}

.UploadPageComponent {
  max-width: 752px;
  margin: auto;
  width: 100%;
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
}

.title {
  color: black;
  font-weight: 900;
  font-size: 40px;
}

.description {
  font-size: 20px;
  color: gray;
  margin-bottom: 30px;
}

.form {
  margin-bottom: 50px;
}

.overlay-container {
  position: relative;
  margin-bottom: 200px;
}

.image-overlay {
  left: 0;
  top: 0;
  position: absolute;
  width: 150px;
  height: 150px;
}

.w-full {
  width: 100%;
}

.primary-button {
    border-radius: 4px;
    background-color: #5F67EC;
    color: #ffffff;
    background: rgb(95,103,236);
    background: linear-gradient(90deg, rgba(95,103,236,1) 0%, rgba(27,40,255,1) 100%);
    height: 36px;
}

.primary-light-button {
  border-radius: 4px;
  background-color: #5F67EC29;
  color: rgb(95,103,236);
 height: 36px;
}

.primary-light-button:hover {
  border-radius: 4px;
  background-color: #5F67EC37;
  color: rgb(95,103,236);
 height: 36px;
}

.text-link {
  background-color: transparent;
    text-decoration: underline;
    color: black;
    -webkit-text-decoration-color: rgba(0,0,0,0.25);
    text-decoration-color: rgba(0,0,0,0.25);
    -moz-text-decoration-color: rgba(0,0,0,0.25);
    box-shadow: none  !important;
}

.text-link:hover, .text-link:active {
  background-color: transparent;
    -webkit-text-decoration-color: rgba(0,0,0,1);
    text-decoration-color: rgba(0,0,0,1);
    -moz-text-decoration-color: rgba(0,0,0,1);
    --tw-shadow: 0;
    box-shadow: none  !important;
}


.hz-container {
  position: relative;
  width: 100%;
  background: #ffffff29;
  display: flex;
  overflow: auto;
  max-width: 100vw;
  border: 1px solid white;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.flex-scroll {
  padding: 16px;
  display: flex;
  width: 120%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
gap: 16px;
}

.nft-card {
  width: auto;
  margin: 4px;
  padding: 8px;
  height:auto;
  object-fit: contain;
}

.attribute-card {
  margin: 4px;
  padding: 8px;
}
.img-container-l {
  margin-top: 0px;
  height:190px;
  width: 190px;
  object-fit: contain;
}

tr {
  width: 100%;
}
tr:nth-child(even) {background: #f9f9f9}
tr:nth-child(odd) {background: #ffffff}
td {
  width: 25%;
}

.dropdown-btn {
  background-color: transparent;
  color: #5f67ec !important;
  box-shadow: none;
}

.dropdown-btn:focus, .dropdown-btn:hover {
  background-color: #5f67ec29;
  color: #5f67ec;
  box-shadow: none;
}