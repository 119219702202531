.RoadmapContainer {
  height: auto;
  padding: 32px 16px;
}

#RoadmapTitle {
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.02em;
}

#RoadmapContent {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.02em;
}

.blur-light {
  position: absolute;
    right: 60px;
    bottom: 22px;
    background: #ff1e00;
    opacity: 1;
    filter: blur(160px);
    transform: rotate(155.04deg);
    height: 300px;
    width: 300px;
}