.ProjectCardContainer {
  width: 100%;
  height: 96px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ProjectNameAndStatusContainer {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.ProjectDescriptionContainer {
  padding-left: 10px;
}

.ProjectActionContainer {
  padding-right: 10px;
}