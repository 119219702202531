@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300;400;600&display=swap');

.PromoContainer {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  
}

.PromoHeaderContainer {
  font-size: 100px;
  line-height: 110%;
  letter-spacing: 0.02em;
  font-weight: 100;
  margin-top: -32px;
  padding: 16px;
  font-family: 'Rowdies';
}

.PromoDetailContainer {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.02em;
  padding: 16px;
}

@media (max-width: 768px) {
  
.PromoHeaderContainer {
  font-size: 42px;
  line-height: 110%;
  letter-spacing: 0.02em;
}
}
