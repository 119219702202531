.ProjectDashboardPage {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 12% 50px auto 50px 12%;
  grid-template-rows: 150px auto 150px;

  text-align: left;
  color: #5F67EC;
}

.Header-Bar-Container {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
}

.ProjectDashboardPageComponent {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
}

.Footer-Bar-Container {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 4;
}

@media (max-width: 768px) {
.ProjectDashboardPage {
  grid-template-columns: 16px 50px auto 50px 16px;
}}