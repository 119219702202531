.AdsContainer {
  /* padding-top: 30px; */
  /* padding-bottom: 30px; */
  display: flex;
  flex-direction: column;
  row-gap: 20px;
 
}

.AdsPictureContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AdsTopicContainer {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.02em;
}

.AdsPictureItemContainer {
  
  height: 350px;
  background: #FFFFFF;
  padding: 16px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border: 1px solid #5F67EC37;
  border-image-slice: 1;
}

.AdsPictureItem {
  background: #f7f7f7;
  width: 100%;
  height: 313px;
}

/* hz-container */

.hz-container {
  position: relative;
  width: 100%;
  background: #ffffff29;
  display: flex;
  overflow: auto;
  max-width: 100vw;
  border: 1px solid white;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.flex-scroll {
  padding: 16px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

}

.nft-card {
  width: auto;
  margin: 4px;
  padding: 8px;
}

.attribute-card {
  margin: 4px;
  padding: 8px;
}
.img-container-l {
  margin-top: 0px;
  height:100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 414px) {
  
}
